.featureBarDiv {
  text-align: center;
  font-weight: 800;
  font-size: 0.9rem !important;
}
@media (max-width: 600px) {
  .featureBarDiv {
    font-size: 0.7rem !important;
  }
}
@media (max-width: 450px) {
  .featureBarDiv {
    font-size: 0.55rem !important;
  }
}
