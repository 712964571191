.NavbarModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 100;
}
.NavbarModalContent {
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
}
.NavbarCloseButton {
  position: absolute !important;
  right: 1em !important;
  top: 0.1em !important;
  color: grey;
  padding: 0 !important;
}
.NavbarImg {
  padding-bottom: 10px;
  margin: auto;
  display: block;
}
.NavbarListItem {
  width: auto;
}
.NavbarDiv {
  background-color: #222;
  color: white;
}
.NavbarModalTitle {
  font-size: 1.5em;
}
.NavbarIcon {
  color: white;
}
@media (max-width: 425px) {
  .NavbarModalTitle {
    font-size: 1.2em;
  }
  .NavbarIcon {
    height: 0.8em;
  }
}
